import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import FontFamily from '@tiptap/extension-font-family';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Blockquote from '@tiptap/extension-blockquote';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import { useEditor } from '@tiptap/react';

import { FontSize } from '../extensions/font-size';
import { BulletList } from '../extensions/bullet-list';
import { OrderedList } from '../extensions/ordered-list';
import { LineHeight } from '../extensions/line-height';
import { Variables, VariablesWithNodeView } from '../extensions/variables';
import { VariableEntity } from '../../../../../services/entities/VariablesEntity';
import { UsedVariable } from '../../../SidePanel/Variables/interfaces';

const staticExtensions = [
  TextStyle,
  FontFamily,
  Blockquote,
  Bold,
  Underline,
  BulletList,
  Code,
  CodeBlock,
  Document,
  Dropcursor,
  Gapcursor,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  Text,
  FontSize,
  LineHeight,
  TextAlign.configure({ types: [Paragraph.name, Heading.name] }),
  Link,
  Highlight,
  Color,
];

type TiptapExtensionsConfig = {
  variables: VariableEntity[];
  onSetUsedVariables?: (usedVariables: UsedVariable[]) => void;
  isPreview?: boolean;
};

export const getTipTapExtensions = ({ variables, isPreview = false, onSetUsedVariables }: TiptapExtensionsConfig) => {
  const VariablesExtensions = isPreview ? Variables : VariablesWithNodeView;
  return [...staticExtensions, VariablesExtensions.configure({ variables, onSetUsedVariables })];
};

export const setTipTapEditorConfig = (editor: ReturnType<typeof useEditor> | null, editable: boolean, tab: 'editor' | 'preview') => {
  if (!editor) return;

  const className = tab === 'editor' ? 'py-mui-tiptap-input' : 'py-mui-tiptap-input py-mui-tiptap-input-preview';

  editor.setOptions({
    editable: tab === 'editor' && editable,
    editorProps: {
      attributes: {
        class: className,
      },
    },
  });
};
