import { SuggestionProps } from '@tiptap/suggestion';

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VariableEntity } from '../../../../../../services/entities/VariablesEntity';
import { formatVariableValueWithPricing } from '../../../../helpers/formatVariableValueWithPricing';
import './variablesList.less';

const variableHeaders = {
  user: 'User Variable',
  proposal: 'Document Variable',
  client: 'Client Variable',
};

export const VariablesList = forwardRef(function VariablesList(props: SuggestionProps<VariableEntity>, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command(item);
    }
  };

  const upHandler = () => {
    const newIndex = (selectedIndex + props.items.length - 1) % props.items.length;
    setSelectedIndex(newIndex);
    setKeyboardSelectedIndex(newIndex);
  };

  const downHandler = () => {
    const newIndex = (selectedIndex + 1) % props.items.length;
    setSelectedIndex(newIndex);
    setKeyboardSelectedIndex(newIndex);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      let handled = false;

      if (event.key === 'ArrowUp') {
        upHandler();
        handled = true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        handled = true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        handled = true;
      }

      return handled;
    },
  }));

  return (
    <div className="tiptap-variables-container">
      {props.items.length ? (
        <ul className="tiptap-variables-list">
          {props.items.map((item, index) => {
            const variableHeader = variableHeaders[item.type];

            return (
              <li className="tiptap-variables-list-item" key={item.name}>
                <button
                  className={`tiptap-variables-button ${index === selectedIndex ? 'highlight' : ''}`}
                  onClick={() => selectItem(index)}
                  onMouseEnter={() => setSelectedIndex(index)}
                  onMouseLeave={() => setSelectedIndex(keyboardSelectedIndex)}
                >
                  <span>
                    {variableHeader && <h3>{variableHeader}</h3>}
                    {item.friendlyName}
                    {item.value !== null && (
                      <>
                        <span className="tiptap-variables-vr">|</span>
                        <span className="tiptap-variables-value">{formatVariableValueWithPricing(item)}</span>
                      </>
                    )}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <div>No Match Found!</div>
      )}
    </div>
  );
});
