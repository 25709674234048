import { useCallback, useEffect } from 'react';
import { GridRowId } from '@mui/x-data-grid-pro';

import { handleAllUsedVariables } from '../../../grid/reduxStore/usedVariablesSlice';
import { UsedVariable } from '../../../SidePanel/Variables/interfaces';
import { useAppDispatch } from '../../../grid/reduxStore/Store';

export const useUsedVariablesUpdate = (rowId: GridRowId, field: string) => {
  const dispatch = useAppDispatch();

  const cellId = `${rowId}-${field}`;
  const onSetUsedVariables = useCallback(
    (usedVariables: UsedVariable[]) => {
      dispatch(handleAllUsedVariables({ targetId: cellId, usedVariables }));
    },
    [dispatch, cellId]
  );

  useEffect(() => {
    return () => {
      onSetUsedVariables([]);
    };
  }, [dispatch, cellId, onSetUsedVariables]);

  return { onSetUsedVariables };
};
