import React, { createContext, useState, ReactNode, useContext } from 'react';
import { Editor } from '@tiptap/react';

export interface ActiveEditorContextProps {
  activeEditor: Editor | null;
  setActiveEditor: (editor: Editor | null) => void;
}

interface ActiveEditorProviderProps {
  children: ReactNode;
}

export const ActiveEditorContext = createContext<ActiveEditorContextProps>({} as ActiveEditorContextProps);

export const ActiveEditorProvider: React.FC<ActiveEditorProviderProps> = ({ children }) => {
  const [activeEditor, setActiveEditor] = useState<Editor | null>(null);

  const context: ActiveEditorContextProps = { activeEditor, setActiveEditor };

  return <ActiveEditorContext.Provider value={context}>{children}</ActiveEditorContext.Provider>;
};

export const useActiveEditor = (): ActiveEditorContextProps => {
  const context = useContext(ActiveEditorContext);

  if (!context) {
    throw new Error('useActiveEditor must be used within an ActiveEditorProvider');
  }

  return context;
};
