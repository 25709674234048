/** @jsxImportSource @emotion/react */
import Heading from './Heading';
import { useActiveEditor } from '../ActiveEditorContext';

import { IEditorToolbar, ILabels } from '../types';
import { Box, styled } from '@mui/material';

import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import TextAlignment from './TextAlignment';
import FontFamily from './FontFamily';
import PyDivider from '../components/generalComponents/PyDivider';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import { Fragment, useEffect, useState } from 'react';
import { PyMuiIconButton } from '../components/generalComponents';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import IcoHighlight from '../../../../icon/icons/IcoHighlight';

const ToolbarWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;

export type TipTapToolbarProps = {
  documentId?: string;
  className?: string;
  toolbar?: IEditorToolbar[];
  labels?: Omit<ILabels, 'editor'>;
};

type ToolbarIcon = {
  name: string;
  icon: React.ReactNode;
  hasDividerBefore: boolean;
  toggleAction: string;
  outlinedColorWhenActive?: string;
  customComponent?: React.ReactNode;
};

export const toolbarIcons: ToolbarIcon[] = [
  {
    name: 'bold',
    icon: <FormatBoldIcon />,
    hasDividerBefore: true,
    toggleAction: 'toggleBold',
  },
  {
    name: 'italic',
    icon: <FormatItalicIcon />,
    hasDividerBefore: false,
    toggleAction: 'toggleItalic',
  },
  {
    name: 'underline',
    icon: <FormatUnderlined />,
    hasDividerBefore: false,
    toggleAction: 'toggleUnderline',
  },
  {
    name: 'highlight',
    icon: <IcoHighlight />,
    hasDividerBefore: false,
    toggleAction: 'toggleHighlight',
    outlinedColorWhenActive: 'rgba(0, 184, 209, 1)',
  },
  {
    name: 'align',
    icon: <FormatItalicIcon />,
    customComponent: <TextAlignment />,
    hasDividerBefore: true,
    toggleAction: 'toggleAlign',
  },
  {
    name: 'bulletList',
    icon: <FormatListBulletedIcon />,
    toggleAction: 'toggleBulletList',
    hasDividerBefore: false,
  },
  {
    name: 'orderedList',
    icon: <FormatListNumberedIcon />,
    toggleAction: 'toggleOrderedList',
    hasDividerBefore: false,
  },
];

// toolbar component for the editor content will be replaced with required components
// based on the toolbar configuration in the further implementation
const TipTapToolbar = ({ className }: TipTapToolbarProps) => {
  const { activeEditor: editor } = useActiveEditor();
  const [activeFormats, setActiveFormats] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!editor) return;

    const updateActiveFormats = () => {
      setActiveFormats(
        toolbarIcons.reduce((acc, { name }) => {
          acc[name] = editor.isActive(name);
          return acc;
        }, {} as Record<string, boolean>)
      );
    };

    editor.on('selectionUpdate', updateActiveFormats);
    editor.on('transaction', updateActiveFormats);

    return () => {
      editor.off('selectionUpdate', updateActiveFormats);
      editor.off('transaction', updateActiveFormats);
    };
  }, [editor]);

  if (!editor) return null;

  return (
    <ToolbarWrapper data-testid="tiptap-toolbar" className={className}>
      <Heading editor={editor} />
      <PyDivider />
      <FontFamily editor={editor} />
      {toolbarIcons.map(({ name, icon, toggleAction, hasDividerBefore, customComponent, outlinedColorWhenActive }) => {
        if (customComponent) {
          return (
            <Fragment key={name}>
              {hasDividerBefore && <PyDivider />}
              {customComponent}
            </Fragment>
          );
        }

        return (
          <Fragment key={name}>
            {hasDividerBefore && <PyDivider />}
            <PyMuiIconButton
              attributeName={name}
              onClick={() => {
                if (toggleAction) {
                  editor.chain().focus()[toggleAction]().run();
                }
              }}
              isActive={activeFormats[name] ?? false}
              outlinedColorWhenActive={outlinedColorWhenActive}
            >
              {icon}
            </PyMuiIconButton>
          </Fragment>
        );
      })}
    </ToolbarWrapper>
  );
};

export default TipTapToolbar;
