import { Content, mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer, ReactRenderer } from '@tiptap/react';
import { Suggestion, SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';
import tippy, { Instance } from 'tippy.js';
import { VariablesList } from '../../components/VariablesList';
import { VariableEntity } from '../../../../../../services/entities/VariablesEntity';
import { VariableNodeView } from './VariableNodeView';
import { formatVariable, updateUsedVariables, updateUsedVariablesThrottled } from './utils';
import { UsedVariable } from '../../../../SidePanel/Variables/interfaces';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    variables: {
      insertVariable: (variable: string, range?: { from: number; to: number }) => ReturnType;
      setVariablesToStorage: (variables: VariableEntity[]) => ReturnType;
    };
  }
}

let popup: any;

export const Variables = Node.create<{ variables: VariableEntity[]; onSetUsedVariables?: (usedVariables: UsedVariable[]) => void }>({
  name: 'variable',

  group: 'inline',

  inline: true,

  // Make the variable node non-selectable for preview mode
  atom: false,
  selectable: false,

  addOptions() {
    return {
      variables: [],
    };
  },

  addStorage() {
    return {
      variables: this.options.variables,
    };
  },

  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-name'),
        renderHTML: (attributes) => {
          if (!attributes.name) {
            return {};
          }

          return {
            'data-name': attributes.name,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const variableEntity: VariableEntity | undefined = this.editor?.storage.variable.variables.find(
      (variable) => variable.name === node.attrs.name
    );
    const valueToRender = formatVariable(node.attrs.name, variableEntity);

    return ['span', mergeAttributes({ 'data-type': this.name, class: 'tiptap-variable' }, HTMLAttributes), valueToRender];
  },

  onCreate() {
    popup = tippy('body', {
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: 'trigger-menu',
      getReferenceClientRect: () => {
        const coords = this.editor.view.coordsAtPos(this.editor.state.selection.$anchor.pos);

        return new DOMRect(coords.left, coords.top, 0, 0);
      },
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      },
    });

    if (this.options.onSetUsedVariables) {
      updateUsedVariables(this.editor, this.options.onSetUsedVariables);
    }
  },

  onUpdate() {
    if (this.options.onSetUsedVariables) {
      updateUsedVariablesThrottled(this.editor, this.options.onSetUsedVariables);
    }
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ state, chain }) => {
          let isVariable = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isVariable = true;
              return chain().setNodeSelection(pos).run();
            }
          });

          return isVariable;
        }),
    };
  },
  addCommands() {
    return {
      insertVariable: (variable, commandRange) => {
        return ({ chain, editor }) => {
          const range = commandRange || {
            from: editor.state.selection.from,
            to: editor.state.selection.to,
          };

          // Check if next character is a space
          const nextChar = editor.state.doc.textBetween(range.to, range.to + 1);
          const needsSpace = nextChar !== ' ';

          const contentToInsert: Content = [{ type: this.name, attrs: { name: variable } }];

          if (needsSpace) {
            contentToInsert.push({ type: 'text', text: ' ' });
          }

          return chain().focus().insertContentAt(range, contentToInsert).run();
        };
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion<VariableEntity>({
        editor: this.editor,
        allowSpaces: false,
        char: '[',
        allow: ({ range, state }) => {
          const includesEndBrackets = state.doc.textBetween(range.from, range.to).includes(']');
          return !includesEndBrackets;
        },
        command: ({ props, range }) => {
          return this.editor.commands.insertVariable(props.name, range);
        },
        items: ({ query }: { query: string }) => {
          const variables: VariableEntity[] = this.editor.storage.variable.variables;
          const search = (val: string) => val.toLowerCase().includes(query.toLowerCase());

          return variables.filter((variable) => search(variable.friendlyName) || search(variable.name));
        },
        render: () => {
          let component: any;
          const tippyPopup: Instance = popup?.[0];
          return {
            onStart: (props: SuggestionProps) => {
              component = new ReactRenderer(VariablesList, {
                props,
                editor: props.editor,
              });

              const getReferenceClientRect = () => {
                const coords = this.editor.view.coordsAtPos(this.editor.state.selection.$anchor.pos);

                return new DOMRect(coords.left, coords.top, 0, 0);
              };

              tippyPopup?.setProps({
                getReferenceClientRect,
                appendTo: document.body,
                content: component.element,
              });

              tippyPopup?.show();
            },

            onUpdate(props: SuggestionProps) {
              component.updateProps(props);

              const { view, state } = props.editor;

              const getReferenceClientRect = () => {
                const coords = view.coordsAtPos(state.selection.$anchor.pos);

                return new DOMRect(coords.left, coords.top, 0, 0);
              };

              tippyPopup?.setProps({
                getReferenceClientRect,
              });
            },

            onKeyDown(props: SuggestionKeyDownProps) {
              if (props.event.key === 'Escape') {
                tippyPopup?.hide();

                return true;
              }

              if (!tippyPopup?.state.isShown) {
                tippyPopup?.show();
              }

              return component.ref?.onKeyDown(props);
            },

            onExit() {
              tippyPopup?.hide();
              if (component) {
                component.destroy();
                component = null;
              }
            },
          };
        },
      }),
    ];
  },
});

export const VariablesWithNodeView = Variables.extend({
  atom: true,
  selectable: true,

  addNodeView() {
    return ReactNodeViewRenderer(VariableNodeView);
  },
});
